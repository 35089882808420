<template>
  <div class="container-fluid">
    <BarraCrud :botaoNovo="true" :onNovo="onNovo" />
    <MedicamentosTable ref="medicamentosTableComp" @novo="onNovo" />
  </div>
</template>

<script>
import BarraCrud from "@/components/common/BarraCrud.vue";
import MedicamentosTable from "@/components/Medicamentos/MedicamentosTable.vue";

export default {
  components: {
    MedicamentosTable,
    BarraCrud,
  },
  methods:{
    onNovo(){
      console.log('novo')
      this.$refs.medicamentosTableComp.novo()
    }
  }
};
</script>

<style></style>
