<template>
  <div>
    <b-form @submit.stop.prevent="salvar()">
      <b-form-group>
        <div class="row">
          <div class="col-12">
            <label> Medicamento</label>
            <b-form-input
              placeholder="Medicamento"
              v-model="medicamento.medicamento"
              :state="validateState('medicamento')"
            />
            <b-form-invalid-feedback>
              O nome do medicamento é obrigatório</b-form-invalid-feedback
            >
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="row">
          <div class="col-12">
            <label> Concentração</label>
            <b-form-input
              placeholder="Concentração"
              v-model="medicamento.concentracao"
              :state="validateState('concentracao')"
            />
            <b-form-invalid-feedback>
              A concentração é obrigatória</b-form-invalid-feedback
            >
          </div>
        </div>
      </b-form-group>

      <div class="bottom-actions">
        <hr />
        <b-btn class="mr-3" variant="primary" type="submit" :disabled="loading" >
          <i class="fas fa-save" v-if="!loading"></i> <b-spinner small v-if="loading"></b-spinner> Salvar</b-btn
        >
        <b-btn variant="secondary" :disabled="loading" > <i class="fas fa-ban"></i> Cancelar</b-btn>
      </div>
    </b-form>
  </div>
</template>

<script>
import MedicamentosLib from "@/libs/MedicamentosLib";
import Validadores from "@/helpers/Validadores";

export default {
  props: {
    medicamentoEdit: { type: Object, required: false },
  },
  data() {
    return {
      medicamento: {
        medicamento: "",
        concentracao: "",
      },
      hasErrors: [],
      loading: false,
    };
  },
  methods: {
    validateState(campo) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError(campo, state) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) {
        this.hasErrors.push({ campo, state });
      } else {
        hasError.state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },
    async validar() {
      
      this.clearErrors();
      this.addOrUpdateError(
        "medicamento",
        !Validadores.string.IsNullOrEmpty(this.medicamento.medicamento)
      );
      this.addOrUpdateError(
        "concentracao",
        !Validadores.string.IsNullOrEmpty(this.medicamento.concentracao)
      );

      await this.$nextTick();
      console.log(" validou? ",this.hasErrors.filter((x) => x.state == true).length >= 2)

      return this.hasErrors.filter((x) => x.state == true).length >= 2;
    },
    async salvar() {
      if (await this.validar()) {
        try {
          this.loading = true;
          let result = await MedicamentosLib.store(this.medicamento);
          if (result.success) {
            this.$swal.fire({
              toast: true,
              title: "Concluído",
              text: "Medicamento salvo com sucesso!",
              icon: "success",
              position: "top-end",
              timer: 1500,
              showConfirmButton: false,
              timerProgressBar: true,
            });

            this.$emit("salvou");
          } else {
            this.$swal.fire({
              toast: true,
              title: "Erro",
              text: "Erro ao salvar medicamento\n" + result.messages,
              icon: "error",
              position: "top-end",
              timer: 1500,
              showConfirmButton: false,
              timerProgressBar: true,
            });
          }
          console.log(result);
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  mounted() {
    if (this.medicamentoEdit) {
      if (this.medicamentoEdit.id) {
        Object.assign(this.medicamento, this.medicamentoEdit);
      }
    }
  },
};
</script>

<style></style>
