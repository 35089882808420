<template>
  <div>
    <b-table
      :items="lista"
      :fields="campos"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
      show-empty
    >

    <template #empty>
      <div>
       <i class="fas fa-capsules"></i> Nenhum medicamento cadastrado, clique em <b-btn variant="primary" @click="$emit('novo')" size="sm" class="mx-2"> <i class="fas fa-plus"></i> Medicamento</b-btn> para cadastrar um medicamento. 
      </div>
    </template>
      <template #table-busy class="text-center">
        <b-spinner></b-spinner> Carregando...
      </template>

      <template #cell(actions)="row">
        <b-button variant="warning" class="mr-3" @click="editar(row.item)">
          <i class="fas fa-edit"></i> Editar
        </b-button>
        <b-button variant="primary" @click="excluir(row.item)">
          <i class="fas fa-trash"> </i> Excluir
        </b-button>
      </template>
    </b-table>

    <b-modal
      id="modal-medicamentos"
      content-class="modalDireito"
      hide-footer
      no-fade
      no-enforce-focus
      title="Cadastrar/Editar Medicamentos"
    >
      <MedicamentosForm @salvou="carrega" :medicamentoEdit="medicamentoEdit" />
    </b-modal>
  </div>
</template>

<script>
import MedicamentosLib from "@/libs/MedicamentosLib";
import MedicamentosForm from "@/components/Medicamentos/MedicamentosForm.vue";

export default {
  components: {
    MedicamentosForm,
  },
  data() {
    return {
      loading: false,

      lista: [],
      campos: [
        {
          key: "id",
          label: "Código",
          sortable: true,
        },
        {
          key: "medicamento",
          label: "Medicamento",
          sortable: true,
        },
        {
          key: "concentracao",
          label: "Concentração",
          sortable: true,
        },
        { key: "actions", label: "", class: "text-nowrap " },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
      medicamentoEdit: {},
    };
  },
  methods: {
    novo() {
      this.medicamentoEdit = {};
      this.$bvModal.show("modal-medicamentos");
    },
    editar(medicamento) {
      if (medicamento) {
        this.medicamentoEdit = medicamento;
        this.$bvModal.show("modal-medicamentos");
      }
    },
    excluir(medicamento) {
      if (medicamento) {
        this.$swal
          .fire({
            title: "Você tem certeza que deseja excluir este Medicamento?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.loading = true;
              const res = await MedicamentosLib.del(medicamento);

              if (res.data && res.success) {
                this.$swal.fire({
                  title: "Medicamento excluído com sucesso!",
                  icon: "success",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
                this.carrega();
              } else {
                this.$swal.fire({
                  title: "Erro ao medicamento!",

                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
              this.loading = false;
            }
          });
      }
    },
    async carrega() {
      this.$bvModal.hide("modal-medicamentos");
      this.loading = true;

      this.lista = await MedicamentosLib.get();
      // if (this.lista.length === 0) {
      //   this.$swal.fire({
      //     title: "Erro ao carregar medicamentos :(!",
      //     icon: "error",
      //     toast: true,
      //     timer: 1500,
      //     position: "top-end",
      //     showConfirmButton: false,
      //   });
      // }
      // console.log(this.lista)
      this.loading = false;
    },
  },
  mounted() {
    this.carrega();
  },
};
</script>

<style></style>
